import React, { useState } from 'react';
import { graphql } from 'gatsby';

import { Page, Menu } from '../components';
import { About, Cases, Manifesto, Solutions, Customers } from '../components/Home';
import { AnimatePresence } from 'framer-motion';

const menu = [
  {
    id: 1,
    title: 'Manifesto',
    href: '#manifesto',
  },

  {
    id: 2,
    title: 'Cases',
    href: '#cases',
  },

  {
    id: 3,
    title: 'Soluções',
    href: '#solucoes',
  },

  {
    id: 4,
    title: 'O Lab721',
    href: '#o-lab721',
  },
];

const IndexPage = (props) => {
  const [isVideoOpen, setIsVideoOpen] = useState(false);

  const {
    allContentfulCases,
    allContentfulAbout,
    allContentfulServiceCategories,
    allContentfulCustomers
  } = props.data;

  console.log(allContentfulCustomers)

  return (
    <Page title="Home" description="Página inicial do site Lab721">
      <AnimatePresence>{!isVideoOpen && <Menu items={menu} />}</AnimatePresence>

      <Manifesto
        about={allContentfulAbout}
        onVideoOpen={(isOpen) => setIsVideoOpen(isOpen)}
      />

      <Cases cases={allContentfulCases} />

      <Customers customers={allContentfulCustomers}/>

      <Solutions services={allContentfulServiceCategories} />

      <About about={allContentfulAbout} />
    </Page>
  );
};

export const pageQuery = graphql`
  query {
    allContentfulCases(filter: { featured: { eq: true } }) {
      edges {
        node {
          id
          title
          slug
          description {
            raw
          }
          services {
            id
            title
          }
          cover {
            fluid(quality: 100) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }

    allContentfulAbout(limit: 1) {
      edges {
        node {
          id
          primeiroTitulo
          primeiroTexto {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }

    allContentfulCustomers {
      edges {
        node {
          id
          title
          link
          logo {
            file {
              url
            }
          }
        }
      }
    }

    allContentfulServiceCategories {
      edges {
        node {
          id
          title
          services {
            title
          }
        }
      }
    }
  }
`;

export default IndexPage;
